<template>
  <div
    class="nosology-item"
    v-if="item"
    :class="{ 'nosology-item_opened': opened }"
  >
    <img class="nosology-item__image" :src="item.image" v-if="item.image" />
    <div
      class="nosology-item__title"
      @click="item.links ? (opened = !opened) : $router.push(item.link)"
    >
      <span class="mr-4 d-xl-none" v-if="item.links">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20.25 8.24994L12.75 15.7499L5.25 8.24994"
            stroke="#3C4242"
            stroke-width="1.5"
            stroke-linecap="square"
          />
        </svg>
      </span>
      <div @click="toDetailNos({ path: item.link }, item.title, item.title)">
        {{ item.title }}
      </div>
    </div>
    <div class="nosology-item__childs" v-if="filteredDiseases">
      <div
        v-for="(child, ind) in filteredDiseases"
        :key="ind"
        @click="toDetailNos(child.link, child.title, item.title)"
        v-html="child.title"
        class="nosology-item__child"
      ></div>
    </div>
    <div
      @click="toDetailNos({ path: item.link }, item.title, item.title)"
      class="nosology-item__link"
    >
      В раздел
      <span class="ml-2">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.75 4.50012L20.25 12.0001L12.75 19.5001"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="square"
          />
          <path
            d="M18.75 12.0001L3.75 12.0001"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="square"
          />
        </svg>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "NosologyItem",
  props: {
    item: Object,
  },
  data: () => ({
    opened: false,
  }),
  computed: {
    filteredDiseases() {
      if (this.item.links && this.item.links.length) {
        return this.item.links;
      }
      return null;
    },
  },
  methods: {
    toDetailNos(to, name, point) {
      const vm = this;
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "therapeutic areas page click", {
          "therapeutic areas page click": {
            "menu item name": name,
            "therapeutic area": point,
             ...this.$root.ymFields
          },
        });
      }
      this.$router.push(to).catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.nosology-item {
  padding: 32px;
  position: relative;
  width: 100%;
  min-height: 440px;
  display: flex;
  flex-direction: column;
  background-color: #ebefee;
  overflow: hidden;
  transition: 0.3s;

  &:hover {
    .nosology-item__image {
      opacity: 0.9;
    }
  }

  @media screen and (max-width: 1220px) {
    max-height: 106px;
    min-height: auto;
    height: 106px;
    padding: 37px;

    &_opened {
      max-height: 4000px;
      height: 418px;

      .nosology-item__childs {
        @media screen and (max-width: 1220px) {
          opacity: 1;
          visibility: visible;
        }
      }

      .nosology-item__title > span {
        transform: rotate(180deg);
      }
    }
  }

  @media screen and (max-width: 767px) {
    max-height: 72px;
    min-height: auto;
    height: 72px;
    padding: 25px;

    &_opened {
      max-height: 4000px;
      height: 420px;

      .nosology-item__title > span {
        transform: rotate(180deg);
      }
    }
  }

  &__image {
    position: absolute;
    bottom: 40px;
    right: 40px;
    width: 200px;
    height: 200px;
    object-fit: contain;
    object-position: center;
    transition: 0.3s;

    @media screen and (max-width: 1220px) {
      top: 0;
      bottom: auto;
      right: 32px;
      height: 106px;
      width: 106px;
      padding: 16px;
    }

    @media screen and (max-width: 767px) {
      height: 72px;
      width: 72px;
      right: 16px;
    }
  }

  &__title {
    cursor: pointer;
    position: relative;
    z-index: 100;
    margin-bottom: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 36px;
    color: #1f1f1f;
    transition: 0.3s;

    &:hover {
      @media screen and (min-width: 1221px) {
        color: #830051;
      }
    }

    > span {
      transition: 0.3s;
    }
    @media screen and (max-width: 767px) {
      max-width: 80%;
      > div {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    @media screen and (max-width: 767px) {
      font-size: 18px;
      line-height: 22px;
    }
  }

  &__childs {
    position: relative;
    z-index: 100;
    padding-right: 40%;
    @media screen and (max-width: 1220px) {
      opacity: 0;
      visibility: hidden;
    }
    @media screen and (max-width: 767px) {
      padding-right: 20%;
    }
  }

  &__child {
    cursor: pointer;
    display: block;
    margin-bottom: 16px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #3c4242;
    transition: 0.3s;

    &:hover {
      color: #d0006f;
    }

    @media screen and (max-width: 767px) {
      font-size: 16px;
      line-height: 22px;
    }
  }

  &__link {
    position: relative;
    z-index: 100;
    margin-top: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #b2b4b4;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      color: #d0006f;
    }
  }
}
</style>